import { ChangeDetectionStrategy, Component, OnInit, inject, input, model, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReferencesService } from '@mca/references/api';
import { BusinessAttribute, BusinessAttributeObjectType, BusinessAttributeValue } from '@mca/shared/domain';
import { dateAsYYYYMMDD } from '@mca/shared/util';
import { NgxMaskDirective } from 'ngx-mask';
import { SelectItem } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { map, tap } from 'rxjs';

interface BusinessAttributeValueUi {
  id: number;
  timestamp: string;
  value: string | number | Date | boolean | null;
}

@Component({
  selector: 'lib-shared-smart-ui-business-attributes',
  templateUrl: './business-attributes.component.html',
  standalone: true,
  imports: [DropdownModule, FormsModule, CalendarModule, InputTextModule, NgxMaskDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessAttributesComponent implements OnInit {
  private referenceService = inject(ReferencesService);

  availableValues = signal<BusinessAttributeValueUi[]>([]);
  attributeMap = signal<Record<number, BusinessAttribute | undefined>>({});
  attributeOptions = signal<SelectItem<number>[]>([]);
  dispValues = signal<BusinessAttributeValueUi[]>([]);

  values = model<BusinessAttributeValue[]>([]);
  objectType = input<keyof typeof BusinessAttributeObjectType>('dba');
  disabled = input(false);
  valueStyleClass = input('col-2');

  booleanOptions = [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
  ];

  getSourceName = (() => {
    let currentName = '';
    return (name: string | undefined, index: number) => {
      if (name !== undefined && (index === 0 || currentName !== name)) {
        currentName = name;
        return name;
      }
      return '';
    };
  })();

  ngOnInit() {
    this.referenceService
      .listReferenceRecord<BusinessAttribute[]>('businessattributes')
      .pipe(
        map(attributes => attributes.filter(attribute => attribute.object_type === this.objectType())),
        tap(attributes => attributes.sort((a, b) => b.source.localeCompare(a.source))),
      )
      .subscribe(attributes => {
        const availableValues = attributes.map(attribute => ({ id: attribute.id, timestamp: dateAsYYYYMMDD(new Date()), value: null }));
        this.attributeMap.set(attributes.reduce((acc, attr) => ({ ...acc, [attr.id]: attr }), {}));
        this.attributeOptions.set(attributes.map(attribute => ({ label: attribute.attribute_name, value: attribute.id })));
        this.availableValues.set(availableValues);
        this.dispValues.set(
          availableValues.map(availableValue => {
            const objectValue = this.values()?.find(value => value.id === availableValue.id);
            return objectValue ? this.dbToUi(objectValue) : availableValue;
          }),
        );
      });
  }

  onValueChange() {
    this.values.set(
      this.dispValues()
        ?.map(value => this.uiToDb(value))
        .filter(value => value.value !== null),
    );
  }

  private dbToUi(entry: BusinessAttributeValue) {
    return {
      ...entry,
      value: this.getUiValue(entry),
    };
  }

  private getUiValue(entry: BusinessAttributeValue) {
    switch (this.attributeMap()[entry.id]?.value_type) {
      case 'number':
      case 'currency':
        return entry.value && +entry.value;
      case 'date':
        return new Date((entry.value ?? '').toString());
      case 'bool':
        return !!entry.value;
    }
    return entry.value;
  }

  private uiToDb(entry: BusinessAttributeValueUi): BusinessAttributeValue {
    return {
      ...entry,
      value: this.getDbValue(entry),
    };
  }

  private getDbValue(entry: BusinessAttributeValueUi): string | number | boolean | null {
    if (entry.value === null) {
      return null;
    }
    switch (this.attributeMap()[entry.id]?.value_type) {
      case 'number':
      case 'currency':
        return +entry.value;
      case 'date':
        return dateAsYYYYMMDD(entry.value) as string;
      case 'bool':
        return !!entry.value;
    }
    return entry.value.toString() || null;
  }
}
